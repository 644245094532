import request from '@/utils/request';

export function getMonthEvents({ year, month }) {
  return request({
    url: 'events/month',
    method: 'get',
    params: {
      year,
      month
    }
  });
}

export function getDayEvents({ year, month, day }) {
  return request({
    url: 'events/day',
    method: 'get',
    params: {
      year,
      month,
      day
    }
  });
}

export function createEvent({ title, start_date, end_date }) {
  return request({
    url: 'events',
    method: 'post',
    data: {
      title,
      start_date,
      end_date
    }
  });
}

export default {
  getMonthEvents,
  getDayEvents,
  createEvent
};
