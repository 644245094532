<template>
  <a class="date-row" href="#" @click.prevent="handleChangeUrl">
    <div class="item start">{{ oneDetail.start_date }}</div>
    <div class="item end">{{ oneDetail.end_date }}</div>
    <div class="item etitle">{{ oneDetail.title }}</div>
  </a>
</template>

<script>
import { getDayEvents } from '@/api/events';

const moment = require('moment');
// @ is an alias to /src

export default {
  props: {
    oneDetail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },

  computed: {
    order_id() {
      return this.oneDetail.group_buying_order.id;
    }
  },
  methods: {
    handleChangeUrl() {
      if (this.order_id) {
        this.$router.push({
          name: 'CreatedGroupInfo',
          params: { group_id: this.order_id }
        });
      } else {
        return;
      }
    }
  }
};
</script>
