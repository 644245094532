<template>
  <div class="main-content">
    <div class="cal-top">
      <div class="month current-date">{{ theTime }}</div>
      <router-link
        class="add-event"
        :to="{ name: 'CreateEvent' }"
      ></router-link>
    </div>
    <div class="cal-detail-list">
      <div class="head-row">
        <div class="item start">開始時間</div>
        <div class="item end">結束時間</div>
        <div class="item etitle">標題</div>
      </div>
      <div class="detail-wrap">
        <OneDetail
          v-for="(oneDetail, index) in calendarList"
          :key="index"
          :oneDetail="oneDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDayEvents } from '@/api/events';
import OneDetail from './one-detail';

const moment = require('moment');
// @ is an alias to /src

export default {
  components: {
    OneDetail
  },
  data() {
    return {
      theTime: this.$route.params.time,
      calendarList: ''
    };
  },

  created() {
    this.getTheDayEvents();
  },
  methods: {
    getTheDayEvents() {
      let arr = this.theTime.split('-');

      let data = {
        year: arr[0],
        month: arr[1],
        day: arr[2]
      };
      console.log(data);
      getDayEvents(data).then(res => {
        this.calendarList = res;
      });
    }
  }
};
</script>
